import React from "react";
import '../terms-of-service/styles.css';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      
      <Helmet>
            <title>Privacy Policy | Ramble</title>
        </Helmet>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> September 1, 2024
      </p>

      <h2>1. Introduction</h2>
      <p>
        Ramble ("we," "our," or "us") is committed to protecting the privacy of
        our users ("you" or "your"). This Privacy Policy explains how we
        collect, use, disclose, and safeguard your information when you use the
        Ramble app. By accessing or using the app, you agree to the terms of
        this Privacy Policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        <strong>Account Information:</strong> When you create an account, we
        collect your email address and username. Email addresses are verified
        via OTP. Usernames and email addresses are unique to each account.
      </p>
      <p>
        <strong>Profile Information:</strong> Your username, name, and profile
        avatar are visible to all users. Any additional personal information
        provided by you will remain private unless you choose to share it.
      </p>
      <p>
        <strong>Wanderer Account Logins:</strong> When logging into a wanderer
        account, we collect the device ID and location information (limited to
        country and state) for security purposes.
      </p>
      <p>
        <strong>Chat Data:</strong> Chats are stored securely on our servers but
        are not encrypted when saved. Messages sent by the account owner are
        marked with a specific icon.
      </p>
      <p>
        <strong>Location Data:</strong> We collect approximate location data
        (country and state) tied to either the account or specific wanderer
        logins.
      </p>

      <h2>3. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>Account Management:</strong> To create and manage your
          account, verify your email, and allow username changes (subject to
          limits on frequency).
        </li>
        <li>
          <strong>Wanderer Access:</strong> To allow and manage temporary access
          to wanderer accounts and monitor for security.
        </li>
        <li>
          <strong>Chat Functionality:</strong> To store chat data securely on
          our servers and facilitate message exchanges between connected users.
        </li>
        <li>
          <strong>Security:</strong> To safeguard your account and data by
          tracking device IDs and location information for wanderer logins, and
          allowing account owners to manage or block access.
        </li>
        <li>
          <strong>App Improvement:</strong> To analyze usage patterns and
          improve the app's functionality.
        </li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <p>
        <strong>Public Information:</strong> Username, name, and profile avatar
        are visible to all users. Private information will not be shared unless
        you choose to disclose it.
      </p>
      <p>
        <strong>Third-Party Service Providers:</strong> We may share your data
        with service providers who assist in the operation of the app, such as
        cloud storage providers, but they are required to protect your
        information in accordance with this Privacy Policy.
      </p>
      <p>
        <strong>Legal Compliance:</strong> We may disclose your information if
        required by law, regulation, or legal process, or to protect the safety,
        rights, or property of Ramble or others.
      </p>

      <h2>5. Security of Your Information</h2>
      <p>
        We implement the highest security measures to protect your information.
        Although chat data is not encrypted when stored, it is transferred
        between devices in a secure manner, ensuring no unauthorized access
        during transmission.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We retain your information for as long as your account is active or as
        needed to provide services. You can delete your account at any time, and
        we will remove your information from our servers, subject to any legal
        requirements.
      </p>

      <h2>7. Your Privacy Choices</h2>
      <ul>
        <li>
          <strong>Account Management:</strong> You can manage your account
          settings, including changing your username (subject to limitations)
          and managing wanderer access.
        </li>
        <li>
          <strong>Wanderer Access:</strong> You can revoke or block wanderer
          logins at any time and restrict access to your chats.
        </li>
        <li>
          <strong>Location Data:</strong> You may choose not to provide location
          data; however, this may limit certain functionalities of the app.
        </li>
      </ul>

      <h2>8. Children's Privacy</h2>
      <p>
        Ramble is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13. If we
        become aware that we have inadvertently collected such information, we
        will take steps to delete it.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        effective immediately upon posting the revised policy within the app.
        Your continued use of the app after the changes are made will constitute
        your acceptance of the changes.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at <a href="mailto:support@rambleconnect.com">support@rambleconnect.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
