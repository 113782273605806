import { createBrowserRouter } from "react-router-dom";
import React from 'react';
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfService from "./pages/terms-of-service";
import AboutUS from "./pages/about-us";
import Contact from "./pages/contact";
import FAQs from "./pages/faqs";

export const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "privacy",
      element:<PrivacyPolicy/>
    },
    {
      path: "terms-of-service",
      element:<TermsOfService/>
    },
    {
      path: 'about',
      element: <AboutUS/>
    },
    {
      path: 'contact',
      element: <Contact/>
    },
    {
      path: 'faqs',
      element: <FAQs/>
    }
  ]);