import Header from './components/header';
import { RouterProvider } from 'react-router-dom';
import Footer from './components/footer';
import React, { useContext } from 'react';
import { router } from './router';
import { AppContext } from './app-context';

function App() {
  const mobileObj = useContext(AppContext)
  return (
    <>
    {!mobileObj.isApp && <Header/>}
    <main style={mobileObj.isApp ?{}: {paddingTop: 80, minHeight: 'calc(100vh - 75px)'}}>
      <RouterProvider router={router}/>
    </main>
    {!mobileObj.isApp && <Footer />}
    </>
  );
}

export default App;
