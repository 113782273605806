import React from "react";

const Header = ()=>{
    return (
        <header className="header">
            <div className="logo">
                <img src="/logo.png" alt="Ramble Logo" />
            </div>
            <div className="content">
                <h1 className="ramble secondary-color">Ramble</h1>
                <p className="tagline primary-color">Where conversations wander.</p>
            </div>
        </header>
    )
}

export default Header