import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <ul className="footer-links">
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/terms-of-service">Terms of Service</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                    <li><a href="/faqs">FAQs</a></li>
                </ul>
                <p className="footer-text">© 2024 Ramble. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
