import React from "react";
import "./styles.css";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <div className="terms-container">
      
      <Helmet>
            <title>Terms of Service | Ramble</title>
        </Helmet>
      <h1>Terms of Service and Conditions</h1>
      <p>
        <strong>Effective Date:</strong> September 1, 2024
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Ramble ("we," "our," or "us"). By accessing or using our app
        ("Ramble"), you agree to comply with and be bound by these Terms of
        Service and Conditions ("Terms"). These Terms govern your use of Ramble
        and outline your rights and responsibilities. If you do not agree with
        any part of these Terms, you must not use the app.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        To use Ramble, you must be at least 13 years old. By using the app, you
        represent and warrant that you are at least 13 years of age and that you
        agree to abide by all applicable laws and regulations.
      </p>

      <h2>3. Account Registration</h2>
      <p>
        <strong>Account Creation:</strong> To access certain features of Ramble,
        you must create an account. When registering, you must provide accurate
        and complete information. You are responsible for maintaining the
        confidentiality of your account credentials and for all activities that
        occur under your account.
      </p>
      <p>
        <strong>Wanderer Accounts:</strong> Wanderer accounts allow others to
        log in using your username or a random app suggestion. Wanderers will
        only have access to your public profile (username, display name, and
        avatar) and unrestricted chats. Personal information remains private and
        inaccessible.
      </p>
      <p>
        <strong>Private Accounts:</strong> Private accounts are fully private,
        meaning no information is visible to others, and your account will not
        appear in any connection suggestions. Only you have access to your
        private account.
      </p>

      <h2>4. User Conduct</h2>
      <p>By using Ramble, you agree to the following:</p>
      <ul>
        <li>
          <strong>Respect for Others:</strong> You will use the app in a manner
          that is respectful to others. Harassment, abuse, or any form of
          harmful behavior is strictly prohibited.
        </li>
        <li>
          <strong>No Unauthorized Access:</strong> You will not attempt to
          access any account or data that you are not authorized to access.
          Unauthorized access, including hacking or bypassing security measures,
          is prohibited.
        </li>
        <li>
          <strong>Compliance with Laws:</strong> You agree to comply with all
          applicable laws and regulations when using Ramble, including those
          regarding privacy, intellectual property, and data protection.
        </li>
      </ul>

      <h2>5. User Content and Intellectual Property</h2>
      <p>
        <strong>User-Generated Content:</strong> You retain ownership of any
        content you create within the app, including messages and profile
        information. By using the app, you grant Ramble a non-exclusive,
        royalty-free, worldwide license to use, display, and distribute your
        content within the app.
      </p>
      <p>
        <strong>Ramble's Intellectual Property:</strong> All content and
        materials within Ramble, including logos, designs, and software, are the
        intellectual property of Ramble and are protected by copyright and other
        intellectual property laws. You may not use, reproduce, or distribute
        any of Ramble’s content without our prior written permission.
      </p>

      <h2>6. Privacy</h2>
      <p>
        Your use of Ramble is subject to our Privacy Policy, which outlines how
        we collect, use, and protect your information. By using the app, you
        agree to the terms of our Privacy Policy.
      </p>

      <h2>7. Security</h2>
      <p>
        While we take measures to protect your data, you are responsible for
        ensuring the security of your account. This includes keeping your login
        credentials confidential and using strong passwords. We are not
        responsible for any loss or damage resulting from unauthorized access to
        your account.
      </p>

      <h2>8. Termination</h2>
      <p>
        <strong>Termination by You:</strong> You may terminate your account at
        any time by following the instructions within the app. Upon termination,
        your access to the app and any associated data will be discontinued.
      </p>
      <p>
        <strong>Termination by Us:</strong> We reserve the right to terminate or
        suspend your account at any time, without notice, for any reason,
        including but not limited to violations of these Terms or engaging in
        harmful behavior within the app.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, Ramble and its affiliates,
        directors, employees, and agents will not be liable for any indirect,
        incidental, special, or consequential damages arising from your use of
        the app, including but not limited to any loss of data, loss of profits,
        or interruption of business.
      </p>

      <h2>10. Disclaimer of Warranties</h2>
      <p>
        Ramble is provided "as is" and "as available" without any warranties of
        any kind, either express or implied. We do not guarantee that the app
        will be error-free, secure, or available at all times. Your use of the
        app is at your own risk.
      </p>

      <h2>11. Governing Law and Dispute Resolution</h2>
      <p>
        <strong>Governing Law:</strong> These Terms are governed by and
        construed in accordance with the laws of [Insert Jurisdiction], without
        regard to its conflict of laws principles.
      </p>
      <p>
        <strong>Dispute Resolution:</strong> Any disputes arising out of or in
        connection with these Terms shall be resolved through binding
        arbitration in accordance with the rules of the [Insert Arbitration
        Institution]. You agree to waive any right to a jury trial or to
        participate in a class action.
      </p>

      <h2>12. Changes to These Terms</h2>
      <p>
        We may update these Terms from time to time. Any changes will be
        effective immediately upon posting the revised Terms within the app.
        Your continued use of Ramble after the changes are made constitutes your
        acceptance of the revised Terms.
      </p>

      <h2>13. Payment and Subscriptions</h2>
      <p>
        Currently, Ramble is free to use. If we introduce paid features or
        subscriptions in the future, we will update these Terms and provide you
        with the option to opt-in to any paid services.
      </p>

      <h2>14. Third-Party Services</h2>
      <p>
        Ramble may contain links to third-party websites or services. We are not
        responsible for the content, privacy policies, or practices of any
        third-party websites or services. Your use of such services is at your
        own risk.
      </p>

      <h2>15. Indemnification</h2>
      <p>
        You agree to indemnify, defend, and hold harmless Ramble and its
        affiliates from and against any claims, liabilities, damages, losses, or
        expenses arising from your use of the app, your violation of these
        Terms, or your violation of any rights of another.
      </p>

      <h2>16. Contact Us</h2>
      <p>
        If you have any questions, concerns, or requests regarding these Terms,
        please contact us at <a href="mailto:support@rambleconnect.com">support@rambleconnect.com</a>. We are committed to
        resolving any concerns you may have.
      </p>
    </div>
  );
};

export default TermsOfService;
