import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState<number|null>(null);

  const faqs = [
    {
        question: "What is Ramble?",
        answer: "Ramble is a social app that allows users to create accounts to connect and chat with others. Users can choose between Private accounts, which are fully private, and Wanderer accounts, which allow temporary access by others. The app emphasizes privacy and security while facilitating dynamic conversations."
      },
      {
        question: "How do I create an account on Ramble?",
        answer: "To create an account, download the Ramble app and sign up using your email address. You’ll need to verify your email through an OTP sent to you. Choose between a Private or Wanderer account based on your preferred privacy settings."
      },
      {
        question: "What is the difference between Private and Wanderer accounts?",
        answer: "Private accounts are fully secure and not visible to others. Your personal information and chats are kept private, and your account won’t appear in connection suggestions. Wanderer accounts allow temporary access by others using your username or a random suggestion. Wanderers can see your public profile and unrestricted chats, but not your private information."
      },
      {
        question: "How long can someone log into a Wanderer account?",
        answer: "Wanderer logins are permitted for up to 24 hours. After this period, access is automatically revoked. As the account owner, you can also manage or remove access to any device at any time."
      },
      {
        question: "How does messaging work in Ramble?",
        answer: "You can send message requests to other users. Once the recipient responds, both users are considered connected and can continue chatting. Messages from the account owner are marked with a special icon for transparency."
      },
      {
        question: "Is my chat data secure on Ramble?",
        answer: "Yes, all chats are stored securely on Ramble’s servers and are transferred between devices using high security standards. While chats are not encrypted when saved, they are protected against unauthorized access and interception."
      },
      {
        question: "What personal information is visible to others?",
        answer: "For Wanderer accounts, others can see your public profile, which includes your username, display name, and avatar. Private account information remains hidden from other users."
      },
      {
        question: "How can I change my username?",
        answer: "Usernames can be changed, but there are restrictions on how frequently you can update them. Please refer to the app’s guidelines or settings for specific details on the change interval."
      },
      {
        question: "What happens if I forget my password?",
        answer: "If you forget your password, you can use the 'Forgot Password' feature in the app to reset it. You will receive instructions via your registered email to create a new password."
      },
      {
        question: "How does Ramble use my location information?",
        answer: "Ramble collects location information, limited to country and state, for safety purposes related to account logins and wanderer sessions. This helps us enhance security and ensure proper functioning of the app. Your precise location is not shared with other users."
      },
      {
        question: "Can I delete my Ramble account?",
        answer: "Yes, you can delete your Ramble account at any time through the app's settings. Once deleted, all your data, including messages and profile information, will be permanently removed from our servers."
      },
      {
        question: "Can I deactivate my account temporarily?",
        answer: "Currently, Ramble does not offer an option to deactivate accounts temporarily. If you wish to stop using the app, you can delete your account and re-register later if you choose to return."
      },
      {
        question: "How can I report a problem or inappropriate behavior?",
        answer: "To report a problem or inappropriate behavior, please use the 'Report' feature available in the app or contact our support team directly. We take all reports seriously and will investigate any issues promptly."
      },
      {
        question: "What should I do if I suspect unauthorized access to my account?",
        answer: "If you suspect unauthorized access to your account, change your password immediately and contact our support team for further assistance. We can help secure your account and investigate any potential breaches."
      },
      {
        question: "What happens if I encounter technical issues with the app?",
        answer: "If you encounter technical issues, please check the app's Help section for troubleshooting tips. If the problem persists, contact our support team for further assistance. We are committed to resolving any issues as quickly as possible."
      },
      {
        question: "How does Ramble handle user data privacy?",
        answer: "Ramble is committed to protecting your privacy. We only collect data necessary for the functioning of the app, such as email addresses, device IDs, and location (country and state). All data is stored securely, and we do not share your personal information with third parties without your consent."
      },
      {
        question: "Are there any age restrictions for using Ramble?",
        answer: "Yes, you must be at least 13 years old to use Ramble. By using the app, you confirm that you meet this age requirement and comply with all applicable laws and regulations."
      },
      {
        question: "What should I do if I encounter a bug or error in the app?",
        answer: "If you encounter a bug or error, please report it through the app's 'Help' section or contact our support team. Provide as much detail as possible about the issue to help us resolve it efficiently."
      },
      {
        question: "How can I provide feedback or suggest new features?",
        answer: "We welcome your feedback and suggestions! You can provide feedback through the 'Feedback' option in the app or contact us directly. Your input helps us improve and add new features to enhance your experience."
      },
      {
        question: "Can I access my chats from multiple devices?",
        answer: "Yes, you can access your chats from multiple devices as long as you are logged into the same account. However, if you are using a Wanderer account, access is limited to the device logged in and the 24-hour session duration."
      },
      {
        question: "What happens to my data if I delete my account?",
        answer: "If you delete your account, all your data, including messages and profile information, will be permanently removed from our servers. This action cannot be undone, so please make sure you wish to permanently delete your account before proceeding."
      },
      {
        question: "How can I change my account type from Private to Wanderer or vice versa?",
        answer: "To change your account type, log in to the app and go to the settings section. You should find an option to switch between Private and Wanderer accounts. Follow the instructions provided to complete the account type change."
      },
      {
        question: "Is there a way to recover deleted messages?",
        answer: "Once messages are deleted, they cannot be recovered. We recommend that you carefully consider before deleting any messages or data."
      },
      {
        question: "Can I block or report other users?",
        answer: "Yes, you can block or report other users through the app. If you experience any inappropriate behavior or harassment, use the 'Report' feature or contact our support team for assistance."
      },
      {
        question: "How do I update my email address or contact information?",
        answer: "You can update your email address or contact information in the app's settings. If you experience any issues or need further assistance, please contact our support team."
      },
      {
        question: "How does Ramble ensure data protection and compliance with regulations?",
        answer: "Ramble follows industry best practices for data protection and complies with relevant regulations. We use secure data storage and transmission methods, monitor for security threats, and regularly update our systems to maintain compliance with data protection laws. All user data is handled with the utmost care and respect for privacy. For more information, please refer to our Privacy Policy."
      },
      {
        question: "Can I export my chat history from Ramble?",
        answer: "Currently, Ramble does not offer a feature to export chat history directly. If you need access to your chat history for any reason, please contact our support team, and we will assist you with the necessary steps."
      },
      {
        question: "How does Ramble handle account security and prevent unauthorized access?",
        answer: "Ramble uses multiple layers of security to prevent unauthorized access, including secure authentication mechanisms, device tracking, and encryption during data transmission. We also monitor for suspicious activities and provide tools for users to manage their own security settings, such as changing passwords and blocking devices."
      },
      {
        question: "What are the specific security measures in place for Wanderer accounts?",
        answer: "Wanderer accounts are protected with temporary access that lasts only 24 hours. We track device IDs to ensure only authorized devices can access the account. Additionally, account owners can manage access permissions and block devices if needed. All interactions are monitored for suspicious activity to maintain security."
      },
      {
        question: "How does Ramble handle data breaches or security incidents?",
        answer: "In the event of a data breach or security incident, Ramble has a response plan in place to address the issue promptly. We notify affected users, investigate the cause, and take corrective actions to prevent future incidents. We also work closely with regulatory bodies to ensure compliance with notification requirements."
      },
      {
        question: "Can I integrate Ramble with other applications or services?",
        answer: "Currently, Ramble does not offer direct integration with other applications or services. However, we are continuously exploring potential integrations and improvements. Stay tuned for updates on this feature in future releases."
      },
      {
        question: "What happens if there is a dispute or issue between connected users?",
        answer: "If there is a dispute or issue between connected users, Ramble encourages users to resolve the matter directly. For serious issues or if you need assistance, you can report the problem through the app or contact our support team for help. We will review the situation and take appropriate action as needed."
      },
      {
        question: "Are there any limits on the number of connections or messages I can have?",
        answer: "There are no specific limits on the number of connections or messages you can have on Ramble. However, we monitor usage to prevent abuse and ensure fair use of the platform. If you experience any issues or need assistance with your account, please contact our support team."
      },
      {
        question: "How can I verify the authenticity of communications or requests on Ramble?",
        answer: "To verify the authenticity of communications or requests, ensure that you are interacting with users through the official Ramble app. Be cautious of any requests or messages that seem suspicious or ask for personal information. If you have concerns, report them through the app or contact our support team for verification."
      },
      {
        question: "What should I do if I receive unwanted messages or spam on Ramble?",
        answer: "If you receive unwanted messages or spam on Ramble, you can block the user or report the messages as spam. Use the app's blocking and reporting features to prevent further unwanted communication. Our support team will review the reports and take appropriate action to address the issue."
      },
      {
        question: "How does Ramble handle user feedback and suggestions?",
        answer: "Ramble values user feedback and suggestions as they help us improve the app and enhance the user experience. You can provide feedback through the app's feedback feature or contact our support team directly. We review all feedback and consider it for future updates and improvements."
      },
      {
        question: "Where do Ramble servers store user data?",
        answer: "Ramble servers store user data in secure data centers located in the AWS Mumbai (ap-south-1) region. The data centers comply with industry standards for security and data protection to ensure the safety and privacy of user information."
      }
  ];

  const handleToggle = (index:number|null) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs">
      
      <Helmet>
            <title>FAQs | Ramble</title>
        </Helmet>
      <h2>Frequently Asked Questions</h2>
      <ul>
        {faqs.map((faq, index) => (
          <li key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => handleToggle(index)}
            >
              {faq.question}
              <span className="faq-toggle">
                {activeIndex === index ? '-' : '+'}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQs;
