import React from "react"
import '../terms-of-service/styles.css'
import Helmet from 'react-helmet'

const AboutUS =()=>{
    return  <div className="terms-container">
        <Helmet>
            <title>About Us | Ramble</title>
        </Helmet>
    <h1>Welcome to Ramble</h1>
    <p>Ramble is a unique social app designed to let conversations flow freely while giving users control over their privacy. With Ramble, users can create two types of accounts: Private and Wanderer. Each account type offers a distinct experience tailored to how you want to engage with others.</p>
    
    <section className="section">
        <h2>Private Accounts</h2>
        <p>Private accounts offer full privacy and control. Your personal information, including chats, remains completely private, and your account will not be visible in any connection suggestions. Only you can log in and access your private content. Messages can only be exchanged after sending a message request and receiving a response from the other user. This ensures that both parties agree to connect before any conversation takes place.</p>
    </section>
    
    <section className="section">
        <h2>Wanderer Accounts</h2>
        <p>Wanderer accounts offer a more open experience. By using a Wanderer account, you allow others to temporarily log in to your account using either your username or a randomly suggested one by the app. Wanderers can see your public profile, which includes your username, name, and avatar, but they cannot access any personal information. They can browse your unrestricted chats and even continue conversations that aren't marked as restricted by the account owner. Wanderer logins are temporary and limited to 24 hours, after which access is automatically revoked. As the account owner, you retain full control and can manage or remove access to any device at any time.</p>
    </section>
    
    <section className="section">
        <h2>Messaging and Connections</h2>
        <p>In Ramble, meaningful connections are at the core of the experience. Users can send message requests to others. Once the recipient responds, the two users are considered connected, allowing further conversations to take place. Messages from the account owner are marked with a special icon, ensuring transparency in who is communicating.</p>
    </section>
    
    <section className="section">
        <h2>Security and Privacy</h2>
        <p>Ramble is committed to protecting your data. All chats are stored securely on Ramble's servers and are transmitted between devices with the highest security standards. While chats are not encrypted when saved, they are safeguarded against unauthorized access. Rest assured, no one can intercept your conversations directly.</p>
    </section>
    
    <section className="section">
        <h2>Account Creation and Management</h2>
        <p>Creating an account on Ramble requires a verified email address. The email and username must be unique to each account. For security reasons, usernames can only be changed after a specific period. For safety purposes, we collect device IDs and location information (limited to country and state) for wanderer logins and when the account owner logs in.</p>
    </section>
    
    <section className="section">
        <h2>Ramble’s Promise</h2>
        <p>Ramble offers a safe and dynamic platform where conversations can wander, yet privacy and control remain in your hands. Whether you prefer full privacy or a more open, social experience, Ramble lets you connect on your terms.</p>
    </section>
</div>
}

export default AboutUS