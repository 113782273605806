import React, { createContext, PropsWithChildren, useEffect, useState } from "react";

export const AppContext = createContext({
    isApp: false,
})

export const AppProvider = ({children}:PropsWithChildren)=>{
    
  const [isApp] = useState(window.location.search.includes('app=true'));
  useEffect(()=>{
    window.matchMedia('(prefers-color-scheme: dark)').matches && isApp  && document.body.classList.add('dark')
  },[])

    return <AppContext.Provider value={{isApp}}>
        {children}
    </AppContext.Provider>
}