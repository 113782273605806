import React from "react"
import { Helmet } from "react-helmet"

const Contact =()=>{
    return <div className="contact-us">
    <Helmet>
        <title>Contact Us | Ramble</title>
    </Helmet>
    <h1>Contact Us</h1>
    <p>If you have any questions or need assistance, please reach out to us at:</p>
    <p className="email-address"><a href="mailto:support@rambleconnect.com">support@rambleconnect.com</a></p>
</div>
}

export default Contact